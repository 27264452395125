<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form class="cus_form" ref="filterForm" :rules="filterRules" :model="filter" inline>
          <el-form-item label="订单编号">
            <el-input v-model.trim="filter.orderNo" placeholder="请输入订单编号" maxlength="12" @keyup.enter.native="reqRecords()" />
          </el-form-item>
          <el-form-item label="邮寄状态">
            <express-status-select :model="filter"></express-status-select>
          </el-form-item>
          <el-form-item label="客户名称">
            <el-input v-model.trim="filter.buyerName" style="width: 226px" placeholder="请输入客户名称" maxlength="16" @keyup.enter.native="reqRecords()" />
          </el-form-item>
          <el-form-item label="发票代码">
            <el-input v-model.trim="filter.invoiceCode" placeholder="请输入发票代码" maxlength="12" @keyup.enter.native="reqRecords()" />
          </el-form-item>
          <el-form-item label="发票号码">
            <el-input v-model.trim="filter.invoiceNo" placeholder="请输入发票号码" maxlength="8" @keyup.enter.native="reqRecords()" />
          </el-form-item>
          <el-form-item label="开票日期">
            <el-date-picker class="date-picker" v-model="filter.invoiceTimeRange" type="daterange" range-separator="" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"> </el-date-picker>
          </el-form-item>
          <el-form-item label="数电发票号码">
            <el-input maxlength="20" v-model.trim="filter.eInvoiceNo" placeholder="请输入数电发票号码" @keyup.enter.native="reqRecords()" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :disabled="recordsLoading" @click="handleQuery">查询</el-button>
            <el-button type="default" :disabled="exportLoading" @click="handleExport">导出</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div class="content-table">
        <el-table ref="recordsTable" :data="records" current-row-key="billingInvoiceId" row-key="billingInvoiceId" stripe border v-loading="recordsLoading" :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelection" style="width: 100%">
          <el-table-column type="selection" width="50" fixed="left"></el-table-column>
          <el-table-column type="index" label="序号" width="50" />
          <el-table-column prop="orderNo" show-overflow-tooltip label="订单编号" width="250" />
          <el-table-column prop="invoiceTime" label="开票日期" width="120" :formatter="handleDateFormat" />
          <el-table-column prop="roomId" label="房间号" width="100" />
          <el-table-column prop="buyerName" show-overflow-tooltip label="客户名称" width="220" />
          <el-table-column prop="expressPosted" label="邮寄状态" width="120">
            <template slot-scope="scope">
              <ExpressStatus :value="scope.row.expressPosted"></ExpressStatus>
            </template>
          </el-table-column>
           <el-table-column prop="eInvoiceNo" label="数电发票号码" min-width="120" />
          <el-table-column prop="expressInformation" show-overflow-tooltip label="邮寄信息" width="350" />
          <el-table-column prop="invoiceCode" label="发票代码" width="120" />
          <el-table-column prop="invoiceNo" label="发票号码" width="100" />
          <el-table-column prop="invoiceStatus" label="发票状态" width="100">
            <template slot-scope="scope">
              <billing-invoice-status :value="scope.row.invoiceStatus"></billing-invoice-status>
            </template>
          </el-table-column>
          <el-table-column prop="processedTime" label="处理时间" width="180" />
          <el-table-column prop="operate" label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <div class="operate-button">
                <el-button type="text" v-if="scope.row.expressPosted" :disabled="expressPostedMarking" style="color: #f5222d" @click="handleMarkExpressUnPosted(scope.row)">标为未寄 </el-button>
                <el-button type="text" v-else :disabled="expressPostedMarking" @click="handleMarkExpressPosted(scope.row)">标为已寄 </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="paging.current" :page-sizes="pageSizes" :page-size="paging.size" layout="total, sizes, prev, pager, next" :total="paging.total"> </el-pagination>
      </div>
    </div>
    <div class="batch-operate-block" v-show="batchOperateVisible">
      <span
        >已选择 <i class="operate-length">{{ batchSelection.length }}</i> 条数据</span
      >
      <div class="operate-action">
        <el-button type="default" @click="handleCancelBatchOperate">取消选择 </el-button>
        <el-button type="primary" :disabled="expressPostedMarking" @click="handleBatchMarkExpressPosted">标为已寄 </el-button>
        <el-button type="danger" :disabled="expressPostedMarking" @click="handleBatchMarkExpressUnPosted">标为未寄 </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ReqInvoiceExpressGetListByPage, ReqInvoiceExpressSetExpressPosted, ReqInvoiceExpressExport } from '@/service/invoice-express';
import ExpressStatusSelect from '@/components/widgets/ExpressStatusSelect';
import ExpressStatus from '@/components/widgets/ExpressStatus';
import BillingInvoiceStatus from '@/components/widgets/BillingInvoiceStatus';
import { tableStyle, pagingMethods, dateOption, changeMethods } from '@/util/mixins';

export default {
  name: 'InvoiceExpressList',
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  components: { ExpressStatusSelect, ExpressStatus, BillingInvoiceStatus },
  data() {
    return {
      filter: {
        invoiceTime: '',
        orderNo: '',
        expressPosted: 0,
        buyerName: '',
        invoiceCode: '',
        invoiceNo: '',
        eInvoiceNo:''
      },
      filterRules: {
        buyerName: [{ min: 0, max: 30, message: '客户名称1~30字符' }],
        invoiceCode: [{ pattern: /\d{1,12}/, message: '发票代码格式有误' }],
        invoiceNo: [{ pattern: /\d{1,8}/, message: '发票号码格式有误' }],
        orderNo: [{ min: 0, max: 30, message: '订单编号1~30字符' }]
      },
      records: [],
      recordsLoading: false,
      paging: {
        total: 0,
        size: 15,
        current: 1
      },
      pageSizes: [15, 30, 50, 100],
      batchSelection: [],
      batchSelectionClass: 'invoice-deleted',
      expressPostedMarking: false,
      exportLoading: false
    };
  },
  computed: {
    batchOperateVisible() {
      return this.batchSelection.length > 0;
    }
  },
  created() {
    this.handleQuery();
  },
  methods: {
    handleQuery() {
      this.paging.current = 1;
      this.paging.searchCount = true;
      this.reqRecords();
    },
    async handleExport() {
      const rsp = await ReqInvoiceExpressExport(this.filter);
      this.downloadFile(rsp);
    },
    handleSizeChange(val) {
      this.paging.size = val;
      this.handleCancelBatchOperate();
      this.reqRecords();
    },
    handleCurrentChange(val) {
      this.paging.current = val;
      this.handleCancelBatchOperate();
      this.reqRecords();
    },
    handleBatchMarkExpressPosted() {
      const selection = this.batchSelection;
      if (selection.length < 1) return;

      let ids = selection.map((item) => {
        return item.billingInvoiceId;
      });
      this.doReqSetExpressPosted(ids, true, (processedTime) => {
        this.batchRefreshExpressPosted(ids, true, processedTime);
      });
    },
    handleBatchMarkExpressUnPosted() {
      const selection = this.batchSelection;
      if (selection.length < 1) return;

      let ids = selection.map((item) => {
        return item.billingInvoiceId;
      });
      this.doReqSetExpressPosted(ids, false, (processedTime) => {
        this.batchRefreshExpressPosted(ids, false, processedTime);
      });
    },
    handleMarkExpressPosted(record) {
      const selection = [record.billingInvoiceId];
      this.doReqSetExpressPosted(selection, true, (processedTime) => {
        record.expressPosted = true;
        record.processedTime = processedTime;
      });
    },
    handleMarkExpressUnPosted(record) {
      const selection = [record.billingInvoiceId];
      this.doReqSetExpressPosted(selection, false, (processedTime) => {
        record.expressPosted = false;
        record.processedTime = processedTime;
      });
    },
    reqRecords() {
      this.recordsLoading = true;
      ReqInvoiceExpressGetListByPage({ filter: this.filter, paging: this.paging }).then((res) => {
        if (res.success) {
          let list = res.data.records;
          this.records = list;
          delete res.data.records;
          this.paging = Object.assign(this.paging, res.data, {
            searchCount: false
          });
        }
        this.recordsLoading = false;
      });
    },
    batchRefreshExpressPosted(ids, posted, data) {
      this.records.forEach((record) => {
        if (ids.indexOf(record.billingInvoiceId) > -1) {
          record.expressPosted = posted;
          record.processedTime = data;
        }
      });
    },
    doReqSetExpressPosted(ids, posted, callback) {
      this.reqSetExpressPosted(ids, posted, (res) => {
        if (res.success) {
          this.$message.success('标记邮寄状态成功');
          if (callback) callback(res.data);
        }
      });
    },
    reqSetExpressPosted(ids, posted, callback) {
      this.expressPostedMarking = true;
      ReqInvoiceExpressSetExpressPosted({
        billingInvoiceIds: ids,
        expressPosted: posted
      })
        .then((res) => {
          this.expressPostedMarking = false;
          if (!res.success) return;

          if (callback) callback(res);
        })
        .catch(() => {
          this.expressPostedMarking = false;
        });
    },
    handleSelection(val) {
      this.batchSelection = val;
    },
    handleCancelBatchOperate() {
      this.batchSelection = [];
      this.$refs.recordsTable.clearSelection();
    },
    /* 时间格式化 */
    fmtDate(row, column) {
      let date = row[column.property];
      if (date == undefined || date == null) {
        return '';
      }
      return this.$moment(date).format('YYYY-MM-DD');
    }
  }
};
</script>
<style lang="scss" scoped>
.cus_form {
  //   padding: 20px 0;
  .el-form-item {
    margin: 20px 40px 0 0;
  }
}
.select-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    span {
      color: #3d94ff;
      margin-right: 20px;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 22px;

      img {
        width: 20px;
        height: 20px;
        vertical-align: sub;
        margin-right: 4px;
      }
    }

    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .select-title span:before {
    content: '|';
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 200px);

  .content-table {
    .operate-button {
      display: flex;
      justify-content: center;

      .el-button {
        padding: 0;
        margin-right: 10px;
      }
    }
  }
}

::v-deep .el-dialog__body {
  padding: 24px 20px;
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-dialog__footer {
  padding: 0px 20px 20px;
  text-align: right;
  box-sizing: border-box;
}

/*批量操作*/
.batch-operate-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 200px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);

  span {
    margin-left: 24px;
    color: #333333;
  }

  .operate-length {
    display: inline-block;
    padding: 0 10px;
    color: #1890ff;
    font-weight: 600;
  }

  .operate-action {
    margin-right: 24px;
    display: inline-block;
  }
}
</style>
